<template>
  <div class="font">
    <ayl-berad-nav :nav="nav" />
    <div class="content-box mt20px">
      <div class="content-main">
        <div class="information-display">
          <div class="information-main">
            <div class="information-main-item">
              <div class="information-main-left">
                <div class="information-main-bg">
                  <i class="iconfont iconicontw"></i>
                </div>
              </div>
              <div class="information-main-left ml20px">
                <div class="title">体温异常人数</div>
                <div class="text">{{topInfo.temperatureCount}}</div>
              </div>
            </div>
          </div>
          <div class="information-main">
            <div class="information-main-item">
              <div class="information-main-left">
                <div class="information-main-bg">
                  <i class="iconfont icon_fuzhi-"></i>
                </div>
              </div>
              <div class="information-main-left ml20px">
                <div class="title">心率异常人数</div>
                <div class="text">{{topInfo.heartRateCount}}</div>
              </div>
            </div>
          </div>
          <div class="information-main">
            <div class="information-main-item">
              <div class="information-main-left">
                <div class="information-main-bg">
                  <i class="iconfont iconxieya"></i>
                </div>
              </div>
              <div class="information-main-left ml20px">
                <div class="title">血压异常人数</div>
                <div class="text">{{topInfo.bloodPressureCount}}</div>
              </div>
            </div>
          </div>
          <div class="information-main">
            <div class="information-main-item">
              <div class="information-main-left">
                <div class="information-main-bg">
                  <i class="iconfont icon-paibanguanli"></i>
                </div>
              </div>
              <div class="information-main-left ml20px">
                <div class="title">血氧异常人数</div>
                <div class="text">{{topInfo.bloodOxygenCount}}</div>
              </div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <ayl-table :table="table">
          <div slot="ctrl-button">
            <!-- <el-button class="ml40px" type="info" style="float: right;margin-left: 20px;" plain>导出</el-button> -->
            <!-- 明细按钮暂时提出来，等接口完善数据去除 -->
            <!-- <el-button class="ml40px" type="info" style="float: right;" plain @click="onDetail">数据明细</el-button> -->
          </div>
        </ayl-table>
      </div>
    </div>
    <ayl-dialog
      :visible.sync="isShowDialog"
      :viewUrl="viewUrlForDialog"
      v-model="dataForDialog"
      :async="true"
      :showBottomBtn="false"
      :width="widthForDialog"
    ></ayl-dialog>
  </div>
</template>

<script>
export default {
  data() {
    // eslint-disable-next-line no-unused-vars
    const vm = this;
    return {
      nav: [{ name: "人员健康管理" }],

      topInfo: {},

      //弹窗有关
      viewUrlForDialog: ``,
      isShowDialog: false,
      dataForDialog: null,
      widthForDialog: "50%",

      common_getBidNameAndIdList: [true, 0, null], //标段管理下拉
      table: {
        api:
          vm.$api_hw.operationSafetyManagement_HealthManageGetPersonHealthList,
        query: {
          bidId: null,
          personTypeList: null,
          sex: null,
          abnormalType: null,
          queryContent: null,
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "bidId",
            placeholder: "请选择",
            option: [],
          },
          {
            type: "cascader_two",
            title: "人员类型",
            model: "personTypeList",
            placeholder: "请选择",
            option: vm.$enums_hw.personnelTypeNew.list,
            // 是否扁平化数据
            flat: [],
            width: "180px",
          },
          {
            type: "select",
            title: "性别",
            model: "sex",
            placeholder: "请选择",
            option: this.$enums_hw.sex.list,
          },
          {
            type: "select",
            title: "异常类别",
            model: "abnormalType",
            placeholder: "请选择",
            option: vm.$enums_hw.abnormalTypeOption.list,
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "姓名/设备号",
          },
        ],
        columns: [
          // {
          //   type: "selection",
          //   width: "50px"
          // },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "姓名",
            key: "name",
            width: "100px",
            align: "center",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true,
                    },
                    on: {
                      click: vm.onInfo.bind(this, ctx.row),
                    },
                  },
                  ctx.row.name || "- -"
                ),
              ]);
            },
          },

          {
            title: "性别",
            width: "80px",
            align: "center",
            key: "sexDes",
          },
          {
            title: "人员类型",
            width: "120px",
            align: "center",
            key: "personTypeDes",
          },
          {
            title: "体温(℃)",
            // width: "120px",
            align: "center",
            key: "bodyTemperature",
            render(h, ctx) {
              console.log(ctx.row.bodyTemperature);
              let color =
                36.3 <= ctx.row.bodyTemperature &&
                ctx.row.bodyTemperature <= 37.3
                  ? "success"
                  : "danger";
              if (ctx.row.bodyTemperature) {
                return h("div", [
                  h(
                    "el-tag",
                    {
                      props: {
                        type: color,
                        effect: "dark",
                      },
                    },
                    ctx.row.bodyTemperature
                  ),
                ]);
              } else {
                return h("div", [h("span", "- -")]);
              }
            },
          },
          {
            title: "心率(次/分)",
            // width: "100px",
            align: "center",
            key: "heartRate",
            render(h, ctx) {
              console.log(ctx.row.heartRate);
              let color =
                60 <= ctx.row.heartRate && ctx.row.heartRate <= 100
                  ? "success"
                  : "danger";
              if (ctx.row.heartRate) {
                return h("div", [
                  h(
                    "el-tag",
                    {
                      props: {
                        type: color,
                        effect: "dark",
                      },
                    },
                    ctx.row.heartRate
                  ),
                ]);
              } else {
                return h("div", [h("span", "- -")]);
              }
            },
          },
          {
            title: "血压(mmHg)",
            // width: "110px",
            align: "center",
            key: "bloodPressure",
            render(h, ctx) {
              if (ctx.row.bloodPressure) {
                let arr = ctx.row.bloodPressure.split("/");
                console.log(arr);
                let color =
                  60 <= arr[0] && arr[0] <= 90 && 90 <= arr[1] && arr[1] <= 140
                    ? "success"
                    : "danger";
                return h("div", [
                  h(
                    "el-tag",
                    {
                      props: {
                        type: color,
                        effect: "dark",
                      },
                    },
                    ctx.row.bloodPressure
                  ),
                ]);
              } else {
                return h("div", [h("span", "- -")]);
              }
            },
          },
          {
            title: "血氧(%)",
            // width: "100px",
            align: "center",
            key: "bloodOxygen",
            render(h, ctx) {
              console.log(ctx.row.bloodOxygen);
              let color =
                90 <= ctx.row.bloodOxygen && ctx.row.bloodOxygen <= 140
                  ? "success"
                  : "danger";
              if (ctx.row.bloodOxygen) {
                return h("div", [
                  h(
                    "el-tag",
                    {
                      props: {
                        type: color,
                        effect: "dark",
                      },
                    },
                    ctx.row.bloodOxygen
                  ),
                ]);
              } else {
                return h("div", [h("span", "- -")]);
              }
            },
          },

          {
            title: "检测时间",
            key: "reportTime",
            width: "170px",
          },
          {
            title: "所属企业",
            showTooltip: true,
            key: "companyName",
          },
          {
            title: "所属标段",
            showTooltip: true,
            key: "bidName",
          },
          // {
          //   title: "排班网格",
          //   width: "100px",
          //   key: "gridName"
          // },

          {
            title: "设备号",
            showTooltip: true,
            key: "deviceId",
          },
          {
            title: "SIM卡号",
            showTooltip: true,
            key: "simCardNumber",
          },
          {
            title: "操作",
            width: "90px",
            align: "center",
            fixed: "right",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true,
                    },
                    on: {
                      click: vm.onDetail.bind(this, ctx.row),
                    },
                  },
                  "数据明细"
                ),
              ]);
            },
          },
        ],
      },
    };
  },

  methods: {
    //数据明细
    onDetail(val) {
      this.$router.push({
        path:
          "/operation-safety-management/personnel-health-management/personnel-health-detail",
        query: { id: val.personId },
      });
    },
    /**
     * 人员详情
     */
    onInfo(val) {
      this.viewUrlForDialog =
        "/district-management/personnel-info/info-personnel";
      this.widthForDialog = "60%";
      //传参给弹窗
      this.dataForDialog = {
        id: val.personId,
      };
      this.isShowDialog = true; //显示弹窗
    },
  },
  async mounted() {
    await this.$search(this.table);
    await this.$api_hw
      .operationSafetyManagement_getAbnormalPersonCount({})
      .then((res) => {
        this.topInfo = res;
      });
    // console.log(this.$search(this.table))
    // for (let i = 0; i < 8; i++) {
    //   this.table.columnspush.push({});
    // }
    // this.table =
  },
};
</script>
<style lang='sass' scoped>
.detail-main
  margin: 0px 30px
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(102,102,102,1)
  line-height: 46px
/deep/ .el-button--primary 
  color: #FFFFFF
  background-color: #1ABC9C
  border-color: #1ABC9C
.information-display
  width: 100%
  height: 100px
  .information-main
    width: 25%
    height: 100px
    display: inline-block
    vertical-align: middle
    .information-main-item
      position: relative
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      width: 205px
      .information-main-left
        display: inline-block
        vertical-align: middle
        .information-main-bg
          width: 50px
          height: 50px
          background: #DDF5F0
          border-radius: 50%
          margin: auto
          i
            color: #1ABC9C
            font-size: 24px
            line-height: 50px
            padding-left: 13px
            .title
              font-size: 16px
              color: rgba(126,129,135,1)
              line-height: 30px
.text
  font-size: 20px
  color: rgba(255,140,26,1)
  line-height: 30px
  text-align: center;
</style>